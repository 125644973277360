import * as React from "react"
import { Heading, Text, Link, UnorderedList, ListItem } from "@chakra-ui/react"
import Layout from "../components/Layout"
import Jumbo from "../components/Jumbo"
import ShepherdsContainer from "../components/ShepherdsContainer"
import golf from "../images/golf.jpg"

const GolfTournament = () => {
  return (
    <Layout>
      <Jumbo imageUrl={golf}>
        <Heading size="2xl">Golf Tournament</Heading>
      </Jumbo>
      <ShepherdsContainer>
        <Text>Thank you for joining us for the 11th Annual Corky Arthur Memorial Golf Tournament!</Text>
        <Text>More info for this year's tournament coming soon.</Text>
        {/* <Heading>11th Annual Corky Arthur Memorial Golf Tournament</Heading>
        <Text as="i">
          Dedicated to feeding hungry kids in Seminole County, FL schools
        </Text>
        <Heading size="lg">Friday, March 10th at Rio Pinar Golf Club</Heading>
        <Text>
          <Link color="red.500" href="https://ezregister.com/events/38009/">
            Sign up
          </Link>{" "}
          for this year's tournament now!
        </Text>
        <Heading mt={4} size="md">
          Prizes for:
        </Heading>
        <UnorderedList>
          <ListItem>1st, 2nd, and 3rd Place Teams</ListItem>
          <ListItem>Longest Drive - Men and Women</ListItem>
          <ListItem>Closest to the Pin - Men and Women</ListItem>
        </UnorderedList>
        <Text as="i">Mulligan packages available for $20.00</Text>
        <Text></Text>
        <Text>Special Thanks to the following Ace Sponsors:</Text>
        <UnorderedList>
          <ListItem>
            <Link color="red.500" href="http://www.compuspayroll.com/">
              Comp US Payroll, Inc.
            </Link>
          </ListItem>
          <ListItem>
            <Link color="red.500" href="http://jaainsurance.com/">
              Jean Arthur Insurance
            </Link>
          </ListItem>
        </UnorderedList> */}
      </ShepherdsContainer>
    </Layout>
  )
}

export default GolfTournament

export const Head = () => <title>Golf Tournament</title>
